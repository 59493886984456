import { Tooltip, createSvgIcon } from '@material-ui/core'
import { Link, navigate } from '@reach/router'
import { useSchools } from 'Educators/SchoolsContext'
import React, { useEffect, useState } from 'react'
import { User } from 'types'
import MenuSchool from './dashboard/MenuSchoolV3'
import { Menu } from '@headlessui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { featureEnabled } from 'featureFlags'

interface IDashboardScreen {
  [x: string]: any
  children: any
  currentUser: User
  propsFooter?: {
    className?: string | undefined
  }
  propsHeader?: {
    propsMenuSchool: {
      disabled?: boolean | undefined
      hidden?: boolean | undefined
      shoAllPremiumSchools?: boolean | undefined
      redirectOnChange?: boolean | undefined
      navigateOnChange?: boolean | undefined
      navigateOnChangeFn?: ((school: any) => string) | undefined
      schoolIdFromURL?: string | undefined
    }
  }
  minimal?: boolean | undefined
  helperCadetCustomLink?: string | undefined
  hideHelperCadet?: boolean | undefined
}

const zendeskAuthLink = (customLink?: string) => {
  const redirectHref = customLink || 'https://closegaphelp.zendesk.com/hc/en-us'
  return `/sign-in/zendesk?return_to=${encodeURIComponent(redirectHref)}`
}

const ClosegapLogo = () => (
  <svg width="154" height="24" viewBox="0 0 154 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Logo" clipPath="url(#clip0_1856_1230)">
      <path
        id="Vector"
        d="M60.6981 18.4359C62.0507 20.0326 64.2536 21.5341 67.2433 21.5341C71.5555 21.5341 72.7808 19.1085 72.7808 17.2877C72.7808 14.2846 69.9484 13.4557 67.0223 12.6573C63.7179 11.7299 60.1926 10.7719 60.1926 6.84482C60.1926 3.36273 63.276 1.16138 67.0826 1.16138C70.1995 1.16138 72.5565 2.27904 74.1936 4.16446L72.9984 5.50634C71.4885 3.65489 69.3492 2.82259 66.9889 2.82259C64.1866 2.82259 62.0808 4.44982 62.0808 6.7497C62.0808 9.27378 64.6922 10.0076 67.5547 10.8059C70.9227 11.7639 74.6657 12.851 74.6657 17.1925C74.6657 19.9069 72.8712 23.1953 67.2065 23.1953C63.7447 23.1953 61.1333 21.7889 59.5297 19.8118L60.6947 18.4393L60.6981 18.4359Z"
        fill="#F3F2F8"
      />
      <path
        id="Vector_2"
        d="M78.9845 1.51123H92.2959V3.17244H80.779V11.0606H92.0749V12.7218H80.779V21.1502H92.2959V22.8114H78.9845V1.51123Z"
        fill="#F3F2F8"
      />
      <path
        id="Vector_3"
        d="M106.582 1.16138C110.043 1.16138 112.561 2.5678 114.479 4.80313L113.127 5.7917C111.647 4.0048 109.257 2.82259 106.582 2.82259C101.64 2.82259 97.8334 6.624 97.8334 12.1783C97.8334 17.7327 101.64 21.5647 106.582 21.5647C109.384 21.5647 111.587 20.1583 112.748 18.9795V13.9007H104.787V12.2395H114.543V19.6793C112.655 21.7855 109.886 23.2225 106.582 23.2225C100.666 23.2225 95.9452 18.7518 95.9452 12.175C95.9452 5.59806 100.666 1.16138 106.582 1.16138Z"
        fill="#F3F2F8"
      />
      <path
        id="Vector_4"
        d="M132.2 17.573H121.028L118.919 22.8114H116.903L125.497 1.51123H127.73L136.321 22.808H134.306L132.197 17.5696L132.2 17.573ZM121.594 15.9118H131.631L126.596 3.29813L121.594 15.9118Z"
        fill="#F3F2F8"
      />
      <path
        id="Vector_5"
        d="M139.405 1.51123H147.272C151.3 1.51123 153.597 4.38522 153.597 7.73821C153.597 11.0912 151.267 13.9652 147.272 13.9652H141.199V22.8114H139.405V1.51123ZM147.082 3.17244H141.196V12.304H147.082C149.85 12.304 151.708 10.4186 151.708 7.73821C151.708 5.05786 149.85 3.17244 147.082 3.17244Z"
        fill="#F3F2F8"
      />
      <path
        id="Vector_6"
        d="M0 12.1783C0 5.63203 4.69046 1.16138 10.5728 1.16138C13.9709 1.16138 16.4886 2.66292 18.2831 4.92882L16.7732 5.91739C15.4507 4.06594 13.154 2.81919 10.5728 2.81919C5.69484 2.81919 1.88824 6.6206 1.88824 12.175C1.88824 17.7293 5.69484 21.5307 10.5728 21.5307C13.154 21.5307 15.4507 20.284 16.7732 18.4325L18.2831 19.3905C16.425 21.721 13.9709 23.1919 10.5728 23.1919C4.69046 23.1953 0 18.7247 0 12.1783Z"
        fill="#F3F2F8"
      />
      <path
        id="Vector_7"
        d="M21.9357 1.51123H23.7302V21.1502H33.8945V22.8114H21.9357V1.51123Z"
        fill="#F3F2F8"
      />
      <path
        id="Vector_8"
        d="M55.5823 5.27298C53.8677 3.08719 51.4257 1.6278 48.7067 1.16295L48.292 1.09216L48.1796 2.57702L48.5207 2.63769C50.8617 3.05407 52.9621 4.32411 54.4352 6.21417C54.9766 6.90882 55.4152 7.66625 55.746 8.46412C53.9443 9.06462 52.3073 9.86388 50.8753 10.8434C49.4493 11.8185 48.2119 12.9811 47.1971 14.2986C45.5733 16.4069 44.5278 18.8978 44.1441 21.5499C42.1962 21.0899 40.4304 20.0287 39.0921 18.4958C37.5059 16.6786 36.6588 14.3512 36.7069 11.943C36.7532 9.61978 37.6364 7.38971 39.1937 5.66285C40.045 4.71921 41.065 3.95969 42.1885 3.41813C42.5471 3.84183 43.0732 4.12185 43.671 4.15044C44.8147 4.20519 45.786 3.32119 45.8407 2.17564C45.896 1.03044 45.0131 0.057161 43.8698 0.00241183C42.7261 -0.0523373 41.7547 0.83167 41.7001 1.97722C41.6997 1.98733 41.7001 1.99709 41.6994 2.00721C40.3576 2.62898 39.1394 3.51821 38.1252 4.63307C36.3086 6.6302 35.2784 9.21596 35.2244 11.9133C35.1687 14.6937 36.147 17.3795 37.9787 19.476C39.7274 21.4777 42.1085 22.7815 44.7116 23.17V23.1752L45.0441 23.2147L45.0608 23.2167C45.0712 23.2181 45.0817 23.2195 45.0925 23.2206L45.5103 23.268L45.5308 22.2344C45.817 19.6542 46.789 17.2264 48.355 15.1934C50.2274 12.7621 52.8664 10.9679 56.2021 9.85586C56.4433 10.8386 56.5307 11.8614 56.4527 12.8897C56.2957 14.9625 55.4967 16.8899 54.1413 18.4633C53.437 19.2811 52.6171 19.9656 51.7095 20.4999C51.3544 20.1222 50.8586 19.876 50.3002 19.8492C49.1565 19.7944 48.1851 20.6784 48.1305 21.824C48.0758 22.9695 48.9584 23.9424 50.1021 23.9972C51.2457 24.0519 52.2171 23.1679 52.2717 22.0224C52.2738 21.9756 52.2742 21.9293 52.2731 21.8832C52.363 21.8327 52.4524 21.7811 52.5412 21.7277C53.5411 21.1269 54.438 20.3771 55.2067 19.4987C56.8061 17.6717 57.7482 15.4253 57.9317 13.0017C58.1423 10.2171 57.3081 7.47236 55.582 5.27228L55.5823 5.27298Z"
        fill="#F3F2F8"
      />
    </g>
    <defs>
      <clipPath id="clip0_1856_1230">
        <rect width="153.6" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const MiniLogo = createSvgIcon(
  <svg width="24" height="24" viewBox="34 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="closegap-mini" clipPath="url(#clip0_1856_1230)">
      <path
        id="Vector_9_mini"
        d="M55.5823 5.27298C53.8677 3.08719 51.4257 1.6278 48.7067 1.16295L48.292 1.09216L48.1796 2.57702L48.5207 2.63769C50.8617 3.05407 52.9621 4.32411 54.4352 6.21417C54.9766 6.90882 55.4152 7.66625 55.746 8.46412C53.9443 9.06462 52.3073 9.86388 50.8753 10.8434C49.4493 11.8185 48.2119 12.9811 47.1971 14.2986C45.5733 16.4069 44.5278 18.8978 44.1441 21.5499C42.1962 21.0899 40.4304 20.0287 39.0921 18.4958C37.5059 16.6786 36.6588 14.3512 36.7069 11.943C36.7532 9.61978 37.6364 7.38971 39.1937 5.66285C40.045 4.71921 41.065 3.95969 42.1885 3.41813C42.5471 3.84183 43.0732 4.12185 43.671 4.15044C44.8147 4.20519 45.786 3.32119 45.8407 2.17564C45.896 1.03044 45.0131 0.057161 43.8698 0.00241183C42.7261 -0.0523373 41.7547 0.83167 41.7001 1.97722C41.6997 1.98733 41.7001 1.99709 41.6994 2.00721C40.3576 2.62898 39.1394 3.51821 38.1252 4.63307C36.3086 6.6302 35.2784 9.21596 35.2244 11.9133C35.1687 14.6937 36.147 17.3795 37.9787 19.476C39.7274 21.4777 42.1085 22.7815 44.7116 23.17V23.1752L45.0441 23.2147L45.0608 23.2167C45.0712 23.2181 45.0817 23.2195 45.0925 23.2206L45.5103 23.268L45.5308 22.2344C45.817 19.6542 46.789 17.2264 48.355 15.1934C50.2274 12.7621 52.8664 10.9679 56.2021 9.85586C56.4433 10.8386 56.5307 11.8614 56.4527 12.8897C56.2957 14.9625 55.4967 16.8899 54.1413 18.4633C53.437 19.2811 52.6171 19.9656 51.7095 20.4999C51.3544 20.1222 50.8586 19.876 50.3002 19.8492C49.1565 19.7944 48.1851 20.6784 48.1305 21.824C48.0758 22.9695 48.9584 23.9424 50.1021 23.9972C51.2457 24.0519 52.2171 23.1679 52.2717 22.0224C52.2738 21.9756 52.2742 21.9293 52.2731 21.8832C52.363 21.8327 52.4524 21.7811 52.5412 21.7277C53.5411 21.1269 54.438 20.3771 55.2067 19.4987C56.8061 17.6717 57.7482 15.4253 57.9317 13.0017C58.1423 10.2171 57.3081 7.47236 55.582 5.27228L55.5823 5.27298Z"
        fill="#F3F2F8"
      />
    </g>
    <defs>
      <clipPath id="clip0_1856_1230">
        <rect width="153.6" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  'MiniLogo',
)

const ProfileIcon = createSvgIcon(
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="person">
      <path
        id="Vector"
        d="M12 12.9093C14.21 12.9093 16 11.1193 16 8.9093C16 6.6993 14.21 4.9093 12 4.9093C9.79 4.9093 8 6.6993 8 8.9093C8 11.1193 9.79 12.9093 12 12.9093ZM12 14.9093C9.33 14.9093 4 16.2493 4 18.9093V19.9093C4 20.4593 4.45 20.9093 5 20.9093H19C19.55 20.9093 20 20.4593 20 19.9093V18.9093C20 16.2493 14.67 14.9093 12 14.9093Z"
        fill="#F3F2F8"
      />
    </g>
  </svg>,
  'Profile',
)

const MonitorIcon = createSvgIcon(
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="table_chart">
      <path
        id="Vector"
        d="M10 10.353H15V21.333H10V10.353ZM17 21.333H20C21.1 21.333 22 20.433 22 19.333V10.333H17V21.333ZM20 3.33301H5C3.9 3.33301 3 4.23301 3 5.33301V8.33301H22V5.33301C22 4.23301 21.1 3.33301 20 3.33301ZM3 19.333C3 20.433 3.9 21.333 5 21.333H8V10.333H3V19.333Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  'Monitor',
)

const ManageIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="settings">
      <path
        id="Vector"
        d="M19.5 12C19.5 11.77 19.49 11.55 19.47 11.32L21.33 9.91C21.73 9.61 21.84 9.05 21.59 8.61L19.72 5.38C19.47 4.94 18.93 4.76 18.47 4.96L16.32 5.87C15.95 5.61 15.56 5.38 15.15 5.19L14.86 2.88C14.8 2.38 14.37 2 13.87 2H10.14C9.63003 2 9.20003 2.38 9.14003 2.88L8.85003 5.19C8.44003 5.38 8.05003 5.61 7.68003 5.87L5.53003 4.96C5.07003 4.76 4.53003 4.94 4.28003 5.38L2.41003 8.62C2.16003 9.06 2.27003 9.61 2.67003 9.92L4.53003 11.33C4.51003 11.55 4.50003 11.77 4.50003 12C4.50003 12.23 4.51003 12.45 4.53003 12.68L2.67003 14.09C2.27003 14.39 2.16003 14.95 2.41003 15.39L4.28003 18.62C4.53003 19.06 5.07003 19.24 5.53003 19.04L7.68003 18.13C8.05003 18.39 8.44003 18.62 8.85003 18.81L9.14003 21.12C9.20003 21.62 9.63003 22 10.13 22H13.86C14.36 22 14.79 21.62 14.85 21.12L15.14 18.81C15.55 18.62 15.94 18.39 16.31 18.13L18.46 19.04C18.92 19.24 19.46 19.06 19.71 18.62L21.58 15.39C21.83 14.95 21.72 14.4 21.32 14.09L19.46 12.68C19.49 12.45 19.5 12.23 19.5 12ZM12.04 15.5C10.11 15.5 8.54003 13.93 8.54003 12C8.54003 10.07 10.11 8.5 12.04 8.5C13.97 8.5 15.54 10.07 15.54 12C15.54 13.93 13.97 15.5 12.04 15.5Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  'Manage',
)

const HelpIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="help">
      <path
        id="Vector"
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 19H11V17H13V19ZM15.07 11.25L14.17 12.17C13.67 12.68 13.31 13.14 13.13 13.86C13.05 14.18 13 14.54 13 15H11V14.5C11 14.04 11.08 13.6 11.22 13.19C11.42 12.61 11.75 12.09 12.17 11.67L13.41 10.41C13.87 9.97 14.09 9.31 13.96 8.61C13.83 7.89 13.27 7.28 12.57 7.08C11.46 6.77 10.43 7.4 10.1 8.35C9.98 8.72 9.67 9 9.28 9H8.98C8.4 9 8 8.44 8.16 7.88C8.59 6.41 9.84 5.29 11.39 5.05C12.91 4.81 14.36 5.6 15.26 6.85C16.44 8.48 16.09 10.23 15.07 11.25Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  'Help',
)

const InsightsIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="insights">
      <g id="Vector">
        <path
          d="M21 8.5C19.55 8.5 18.74 9.94 19.07 11.01L15.52 14.57C15.22 14.48 14.78 14.48 14.48 14.57L11.93 12.02C12.27 10.95 11.46 9.5 10 9.5C8.55 9.5 7.73 10.94 8.07 12.02L3.51 16.57C2.44 16.24 1 17.05 1 18.5C1 19.6 1.9 20.5 3 20.5C4.45 20.5 5.26 19.06 4.93 17.99L9.48 13.43C9.78 13.52 10.22 13.52 10.52 13.43L13.07 15.98C12.73 17.05 13.54 18.5 15 18.5C16.45 18.5 17.27 17.06 16.93 15.98L20.49 12.43C21.56 12.76 23 11.95 23 10.5C23 9.4 22.1 8.5 21 8.5Z"
          fill="currentColor"
        />
        <path
          d="M15 9.5L15.94 7.43L18 6.5L15.94 5.57L15 3.5L14.08 5.57L12 6.5L14.08 7.43L15 9.5Z"
          fill="currentColor"
        />
        <path d="M3.5 11.5L4 9.5L6 9L4 8.5L3.5 6.5L3 8.5L1 9L3 9.5L3.5 11.5Z" fill="#B07FF1" />
      </g>
    </g>
  </svg>,
  'Insights',
)

const DiscoveryIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="query_stats">
      <path
        id="Vector"
        d="M19.8837 17.9603C20.3637 17.1903 20.6337 16.2903 20.5737 15.3003C20.4437 13.1503 18.7337 11.3303 16.6037 11.1003C13.8837 10.8003 11.5837 12.9103 11.5837 15.5703C11.5837 18.0603 13.5937 20.0703 16.0737 20.0703C16.9537 20.0703 17.7737 19.8103 18.4637 19.3703L20.8737 21.7803C21.2637 22.1703 21.9037 22.1703 22.2937 21.7803C22.6837 21.3903 22.6837 20.7503 22.2937 20.3603L19.8837 17.9603ZM16.0837 18.0703C14.7037 18.0703 13.5837 16.9503 13.5837 15.5703C13.5837 14.1903 14.7037 13.0703 16.0837 13.0703C17.4637 13.0703 18.5837 14.1903 18.5837 15.5703C18.5837 16.9503 17.4637 18.0703 16.0837 18.0703ZM15.7237 9.57029C14.9837 9.59029 14.2737 9.75029 13.6237 10.0203L13.0737 9.19029L9.99371 14.2003C9.63371 14.7803 8.82371 14.8403 8.38371 14.3303L6.26371 11.8603L3.20371 16.7603C2.89371 17.2503 2.23371 17.3803 1.76371 17.0403C1.34371 16.7303 1.22371 16.1503 1.50371 15.7003L5.28371 9.65029C5.64371 9.08029 6.45371 9.02029 6.89371 9.53029L9.00371 11.9903L12.1837 6.82029C12.5637 6.20029 13.4637 6.18029 13.8637 6.79029L15.7237 9.57029ZM18.3137 10.0703C17.6737 9.79029 16.9837 9.62029 16.2637 9.58029L20.8037 2.39029C21.1137 1.90029 21.7737 1.78029 22.2337 2.12029C22.6637 2.43029 22.7737 3.02029 22.4937 3.46029L18.3137 10.0703Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  'Discovery',
)

const HomeIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="home">
      <path
        id="Vector"
        d="M9.99778 19.3279V14.3279H13.9978V19.3279C13.9978 19.8779 14.4478 20.3279 14.9978 20.3279H17.9978C18.5478 20.3279 18.9978 19.8779 18.9978 19.3279V12.3279H20.6978C21.1578 12.3279 21.3778 11.7579 21.0278 11.4579L12.6678 3.92785C12.2878 3.58785 11.7078 3.58785 11.3278 3.92785L2.96778 11.4579C2.62778 11.7579 2.83778 12.3279 3.29778 12.3279H4.99778V19.3279C4.99778 19.8779 5.44778 20.3279 5.99778 20.3279H8.99778C9.54778 20.3279 9.99778 19.8779 9.99778 19.3279Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  'Home',
)

export const MessagesIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="message">
      <path
        id="Vector"
        d="M20 2H4C2.9 2 2.01 2.9 2.01 4L2 22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM17 14H7C6.45 14 6 13.55 6 13C6 12.45 6.45 12 7 12H17C17.55 12 18 12.45 18 13C18 13.55 17.55 14 17 14ZM17 11H7C6.45 11 6 10.55 6 10C6 9.45 6.45 9 7 9H17C17.55 9 18 9.45 18 10C18 10.55 17.55 11 17 11ZM17 8H7C6.45 8 6 7.55 6 7C6 6.45 6.45 6 7 6H17C17.55 6 18 6.45 18 7C18 7.55 17.55 8 17 8Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  'Messages',
)

const LockIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#6C0FE6" className="w-3 h-3">
    <path
      fillRule="evenodd"
      d="M8 1a3.5 3.5 0 0 0-3.5 3.5V7A1.5 1.5 0 0 0 3 8.5v5A1.5 1.5 0 0 0 4.5 15h7a1.5 1.5 0 0 0 1.5-1.5v-5A1.5 1.5 0 0 0 11.5 7V4.5A3.5 3.5 0 0 0 8 1Zm2 6V4.5a2 2 0 1 0-4 0V7h4Z"
      clipRule="evenodd"
    />
  </svg>
)

const LockWrapper = ({ children, locked }: { children: React.ReactNode; locked: boolean }) => (
  <div className="relative">
    {locked && (
      <div className="text-yellow-400 absolute  top-[-3px] left-[-3px]">
        <LockIcon />
      </div>
    )}
    {children}
  </div>
)

const DemoBlocker = ({ children, demo }: { children: React.ReactNode; demo: boolean }) => {
  if (demo) {
    return (
      <Tooltip
        title={
          "In this demo, you won't have access to the roster feature for the fictional school. Head over to your school's roster section, where you'll find a setup flow to guide you. If you need further assistance, please reach out to us through the help center."
        }
        placement="top"
      >
        <div>
          <div className="pointer-events-none">{children}</div>
        </div>
      </Tooltip>
    )
  }
  return <>{children}</>
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DashboardScreenV3 = ({ children, propsHeader, currentUser, minimal }: IDashboardScreen) => {
  const [sidebarOpen, setSidebarOpen] = useState(
    localStorage.getItem('sidebarOpen') === 'open' ? true : false,
  )
  const [notificationsVisible, setNotificationsVisible] = useState(false)
  useEffect(() => {
    const handler = (event: { data: any }) => {
      const data = event.data
      if (data.message === 'notifications_badge_controller:connect') {
        setNotificationsVisible(true)
      }
      if (data.message === 'redirect_to_page:search-school') {
        navigate('/search-school')
      }
      if (data.message === 'redirect_to_page:dashboard') {
        navigate('/dashboard')
      }
    }

    window.addEventListener('message', handler)

    // clean up
    return () => window.removeEventListener('message', handler)
  }, []) // empty array => run only once

  const toggleSidebar = () => {
    localStorage.setItem('sidebarOpen', sidebarOpen ? 'closed' : 'open')
    setSidebarOpen(!sidebarOpen)
  }
  const { school, role } = useSchools()
  const checkIsPremium = (requiresPremium: boolean) => (requiresPremium ? school.premium : true)
  const propsMenuSchool = propsHeader?.propsMenuSchool

  return (
    <div className="bg-gray-50 w-full max-w-full h-dvh">
      <div className="w-full px-6 py-4 bg-purple-950 shadow items-center inline-flex">
        <Link to="/">
          <div className="hidden sm:block">
            <ClosegapLogo />
          </div>
          <div className=" sm:hidden">
            <MiniLogo />
          </div>
          <span className="hidden">Home</span>
        </Link>

        <MenuSchool {...propsMenuSchool} />
        <div className="flex items-center gap-2 p-2 ml-auto relative">
          <Menu>
            <Menu.Button className="p-2 rounded-full bg-[#4D1093]">
              <ProfileIcon />
              <span className="hidden">Open Profile Menue</span>
            </Menu.Button>
            <Menu.Items className="absolute bg-white top-full left-full -translate-x-full flex flex-col w-40 z-10 rounded-lg shadow divide-y">
              <Menu.Item>
                <Link className="p-2 text-center text-lg font-normal" to="/dashboard/profile">
                  My profile
                </Link>
              </Menu.Item>
              <Menu.Item>
                <a
                  href={zendeskAuthLink()}
                  target="_blank"
                  className="p-2 text-center text-lg font-normal sm:hidden"
                  rel="noopener noreferrer"
                >
                  Help Center
                </a>
              </Menu.Item>
              <Menu.Item>
                <a
                  href="https://www.closegap.org/user-agreement"
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" md:hidden p-2 text-center text-lg font-normal"
                >
                  Terms
                </a>
              </Menu.Item>
              <Menu.Item>
                <a
                  href="https://www.closegap.org/privacypolicy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" md:hidden p-2 text-center text-lg font-normal"
                >
                  Privacy
                </a>
              </Menu.Item>
              <Menu.Item>
                <Link
                  className="p-2 text-center text-lg font-normal text-[#FB4B7A]"
                  to="/sign-out?clear=true"
                >
                  Log out
                </Link>
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
      </div>
      <div className="flex flex-col-reverse sm:flex-row">
        {!minimal && school.reviewed && !school.rejected && !(role === 'pending') && (
          <nav
            className="group/sidebar sm:h-[calc(100vh-88px)] py-4 sm:py-10 px-4 sm:px-6 bg-violet-100 flex-row sm:flex-col justify-between items-start inline-flex relative"
            data-sidebar={sidebarOpen ? 'open' : 'closed'}
          >
            <button
              onClick={() => toggleSidebar()}
              className="hidden md:block absolute top-1 right-0 p-1 rounded-full bg-violet-100 shadow-md z-10 translate-x-1/2"
            >
              {sidebarOpen ? (
                <ChevronLeftIcon className="h-5 w-5" />
              ) : (
                <ChevronRightIcon className="h-5 w-5" />
              )}
              <span className="hidden">{sidebarOpen ? 'Close Sidebar' : 'Open Sidebar'}</span>
            </button>
            <div className="flex flex-row sm:flex-col gap-4 w-full justify-around sm:justify-normal">
              <Link
                to={`/v2/schools/${school.id}/home`}
                className="group flex items-center md:gap-2 p-2 md:w-full aria-[current=page]:bg-[#D4C4FB] rounded-lg"
              >
                <HomeIcon className="h-5 w-5 text-[#B07FF1] group-aria-[current=page]:text-[#6C0FE6]" />
                <span className="text-[#2E0052] text-lg font-normal group-aria-[current=page]:font-medium leading-none hidden group-data-[sidebar=open]/sidebar:md:block">
                  Home
                </span>
              </Link>
              <Link
                to={`/v2/schools/${school.id}/monitoring`}
                className="group flex items-center md:gap-2 p-2 md:w-full aria-[current=page]:bg-[#D4C4FB] rounded-lg"
              >
                <MonitorIcon className="h-5 w-5 text-[#B07FF1] group-aria-[current=page]:text-[#6C0FE6]" />
                <span className="text-[#2E0052] text-lg font-normal group-aria-[current=page]:font-medium leading-none hidden group-data-[sidebar=open]/sidebar:md:block">
                  Monitoring
                </span>
              </Link>

              {featureEnabled(currentUser, 'message_center') && (
                <Link
                  to={`/v2/schools/${school.id}/messages`}
                  className="group relative flex items-center md:gap-2 p-2 md:w-full aria-[current=page]:bg-[#D4C4FB] rounded-lg"
                >
                  <div className="relative">
                    <MessagesIcon className="h-5 w-5 text-[#B07FF1] group-aria-[current=page]:text-[#6C0FE6]" />
                    <iframe
                      src={`${process.env.REACT_APP_API_URL}/app/notifications?school_id=${school.id}`}
                      className="absolute min-w-0 min-x-0 w-fit h-fit max-w-20 top-[-10px] left-[13px] pointer-events-none"
                      title="Notifications"
                      style={{ visibility: notificationsVisible ? 'visible' : 'hidden' }}
                    />
                  </div>
                  <span className="text-[#2E0052] text-lg font-normal group-aria-[current=page]:font-medium leading-none hidden group-data-[sidebar=open]/sidebar:md:block">
                    Messages
                  </span>
                </Link>
              )}
              <Link
                to={`/v2/schools/${school.id}/insights`}
                className="group flex items-center  md:gap-2 p-2 md:w-full  aria-[current=page]:bg-[#D4C4FB] rounded-lg"
              >
                <LockWrapper locked={!checkIsPremium(true)}>
                  <InsightsIcon className="h-5 w-5 text-[#B07FF1] group-aria-[current=page]:text-[#6C0FE6]" />
                </LockWrapper>
                <span className="text-[#2E0052] text-lg font-normal group-aria-[current=page]:font-medium leading-none hidden group-data-[sidebar=open]/sidebar:md:block">
                  Insights
                </span>
              </Link>
              <Link
                to={`/v2/schools/${school.id}/discovery`}
                className="group flex items-center md:gap-2 p-2 md:w-full  aria-[current=page]:bg-[#D4C4FB] rounded-lg"
              >
                <LockWrapper locked={!checkIsPremium(true)}>
                  <DiscoveryIcon className="h-5 w-5 text-[#B07FF1] group-aria-[current=page]:text-[#6C0FE6]" />
                </LockWrapper>
                <span className="text-[#2E0052] text-lg font-normal group-aria-[current=page]:font-medium leading-none hidden group-data-[sidebar=open]/sidebar:md:block">
                  Discovery
                </span>
              </Link>
              {featureEnabled(currentUser, 'fidelity') && (
                <Link
                  to={`/v2/schools/${school.id}/fidelity`}
                  className="group relative flex items-center md:gap-2 p-2 md:w-full aria-[current=page]:bg-[#D4C4FB] rounded-lg"
                >
                  <LockWrapper locked={!checkIsPremium(true)}>
                    <DiscoveryIcon className="h-5 w-5 text-[#B07FF1] group-aria-[current=page]:text-[#6C0FE6]" />
                  </LockWrapper>
                  <span className="text-[#2E0052] text-lg font-normal group-aria-[current=page]:font-medium leading-none hidden group-data-[sidebar=open]/sidebar:md:block">
                    Fidelity
                  </span>
                </Link>
              )}
              <DemoBlocker demo={school.demo}>
                <Link
                  to={
                    featureEnabled(currentUser, 'manage_tab_v2')
                      ? `/v2/schools/${school.id}/manage`
                      : '/manage-school'
                  }
                  className="group flex items-center  md:gap-2 p-2 md:w-full  aria-[current=page]:bg-[#D4C4FB] rounded-lg sm:hidden"
                >
                  <LockWrapper locked={school.demo}>
                    <ManageIcon className="h-5 w-5 text-[#B07FF1] group-aria-[current=page]:text-[#6C0FE6]" />
                  </LockWrapper>
                  <span className="text-[#2E0052] text-lg font-normal group-aria-[current=page]:font-medium leading-none hidden group-data-[sidebar=open]/sidebar:md:block">
                    Manage
                  </span>
                </Link>
              </DemoBlocker>
            </div>
            <div className="sm:flex flex-row sm:flex-col gap-4 w-full hidden ">
              <DemoBlocker demo={school.demo}>
                <Link
                  to={
                    featureEnabled(currentUser, 'manage_tab_v2')
                      ? `/v2/schools/${school.id}/manage`
                      : '/manage-school'
                  }
                  className="group flex items-center  md:gap-2 p-2 md:w-full  aria-[current=page]:bg-[#D4C4FB] rounded-lg"
                >
                  <LockWrapper locked={school.demo}>
                    <ManageIcon className="h-5 w-5 text-[#B07FF1] group-aria-[current=page]:text-[#6C0FE6]" />
                  </LockWrapper>
                  <span className="text-[#2E0052] text-lg font-normal group-aria-[current=page]:font-medium leading-none hidden group-data-[sidebar=open]/sidebar:md:block">
                    Manage
                  </span>
                </Link>
              </DemoBlocker>
              <a
                href={zendeskAuthLink()}
                target="_blank"
                className="group flex items-center  md:gap-2 p-2 md:w-full  aria-[current=page]:bg-[#D4C4FB] rounded-lg"
                rel="noopener noreferrer"
              >
                <HelpIcon className="h-5 w-5 text-[#B07FF1] group-aria-[current=page]:text-[#6C0FE6]" />
                <span className="text-[#2E0052] text-lg font-normal group-aria-[current=page]:font-medium leading-none hidden group-data-[sidebar=open]/sidebar:md:block text-nowrap">
                  Help Center
                </span>
              </a>
              <div className="hidden group-data-[sidebar=open]/sidebar:md:flex items-center gap-2 px-2">
                <a
                  href="https://www.closegap.org/user-agreement"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#2E0052]/50 hover:text-[#2E0052]/75 text-xs font-semibold"
                >
                  Terms
                </a>
                <a
                  href="https://www.closegap.org/privacypolicy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#2E0052]/50 hover:text-[#2E0052]/75 text-xs font-semibold"
                >
                  Privacy
                </a>
              </div>
            </div>
          </nav>
        )}
        <div className="h-[calc(100vh-88px-72px)] sm:h-[calc(100vh-88px)] w-full overflow-y-scroll bg-[#F2F1F8] ">
          {children}
        </div>
      </div>
    </div>
  )
}

export { DashboardScreenV3 }
